<template>
	<errorContainer :error="erreur" :warning="warning">
		<div class="animated fadeIn">
			<div v-if="$screen.width <= 576" class="container-mobile">
				<div class="my-expense-mobile">
					<div class="title">
						<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
							{{ FormMSG(6, 'My expenses') }}
						</h1>
					</div>
					<div class="my-expense-actions" :class="{ 'pt-16': $isPwa() }" v-if="project.useExpense">
						<div class="d-flex justify-content-start title-add mt-2">{{ FormMSG(11, 'Add Expense') }}</div>
						<div class="d-flex justify-content-center wapper">
							<router-link to="/myexpenses/1/n/2" class="btn-action gap-right" tag="div" v-if="project.useExpenseTravel">
								<img src="svgs/mileage.svg" alt="Mileage" />
								<span>{{ FormMSG(12, 'Mileage') }}</span>
							</router-link>
							<router-link to="/myexpenses/2/n/0" class="btn-action gap-right" tag="div" v-if="project.useExpenseTicket">
								<img src="svgs/expense.svg" alt="Ticket" />
								<span>{{ FormMSG(13, 'Ticket') }}</span>
							</router-link>
							<router-link
								to="/myexpenses/3/n/1"
								class="btn-action"
								:class="`${project.useKlippaOcr ? 'gap-right' : ''}`"
								tag="div"
								v-if="!isFilmSingle && project.useExpenseInvoice"
							>
								<img src="svgs/invoice.svg" alt="Invoice" />
								<span>{{ FormMSG(14, 'Invoice') }}</span>
							</router-link>
							<!-- <div class="btn-action" @click="handleCreateKlippaOcrOpenModal({ isMultipe: false })" v-if="project.useKlippaOcr">
								<img src="svgs/expense.svg" alt="Ocr" />
								<span>{{ FormMSG(343, 'Ticket OCR') }}</span>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="form">
				<b-row align-h="between" v-if="$screen.width >= 576">
					<!-- class="hide-on-tablet" --->
					<b-col sm="4">
						<h1>{{ FormMSG(6, 'My expenses') }}</h1>
					</b-col>
					<b-col sm="8" class="text-right">
						<b-button-group v-if="project.useExpense">
							<b-dropdown right :text="FormMSG(5, 'Create...')" variant="success mr-2">
								<b-dropdown-item v-if="project.useExpenseTravel" @click="openExpenseModal(2, true)">
									{{ FormMSG(1, 'Mileage') }}
								</b-dropdown-item>
								<!-- Travel -->
								<b-dropdown-item v-if="project.useExpenseTicket" @click="openExpenseModal(0, true)">
									{{ FormMSG(2, 'Ticket') }}
								</b-dropdown-item>
								<b-dropdown-item @click="handleCreateKlippaOcrOpenModal({ isMultipe: false, isSplitTva: true })" v-if="project.useKlippaOcr">
									{{ FormMSG(4334, 'Create one ticket from pictures') }}
								</b-dropdown-item>
								<b-dropdown-item @click="handleCreateKlippaOcrOpenModal({ isMultipe: true })" v-if="project.useKlippaOcr">
									{{ FormMSG(43883, 'Create multiple ticket from pictures (one picture/ticket)') }}
								</b-dropdown-item>
								<b-dropdown-item v-if="!isFilmSingle && project.useExpenseInvoice" @click="openExpenseModal(1, true)">
									{{ FormMSG(3, 'Invoice') }}
								</b-dropdown-item>
							</b-dropdown>
						</b-button-group>
					</b-col>
				</b-row>
				<b-row v-if="Expenses">
					<div class="d-flex flex-column w-100">
						<b-card no-body :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
							<b-tabs v-model="currentExpenseTab">
								<b-tab v-if="canSeeApprove && $screen.width > 576" :title="FormMSG(1111, 'Approve')">
									<div class="pr-1 pl-1">
										<ValidateExpenses />
									</div>
								</b-tab>
								<b-tab lazy :title="tabOneCaption()">
									<div v-if="$screen.width <= 576" class="animated fadeIn pr-1 pl-1">
										<div class="container-mobile">
											<div class="my-expense-mobile">
												<div class="my-expense-status-actions p-1">
													<div
														class="check-actions cursor-pointer"
														v-if="notSubmittedState && notSubmittedCopyIds.length > 0"
														@click="switchCheckAll"
													>
														<div class="d-flex text-left" v-if="checkAll">
															{{ FormMSG(45, 'All') }} &nbsp;
															<component :is="getLucideIcon('CheckSquare')" :size="16" />
														</div>
														<div class="d-flex" v-else>
															{{ FormMSG(46, 'None') }}&nbsp;
															<component :is="getLucideIcon('Square')" :size="16" />
														</div>
													</div>
												</div>
												<div class="content pb-3" v-if="expenses.length > 0">
													<div
														class="card-expense"
														v-for="expense in expenses"
														:key="expense.id"
														:id="`${
															notSubmittedState && parseInt(expense.validated, 10) === 0
																? generateTextId('card-expense-' + expense.id)
																: ''
														}`"
													>
														<div class="wrapper">
															<div class="resume">
																<div class="first-col" v-if="notSubmittedState && parseInt(expense.validated, 10) === 0">
																	<b-form-checkbox
																		v-if="+expense.amountTotal !== 0"
																		size="lg"
																		v-model="notSubmittedIds"
																		:value="expense.id"
																		@change="setSelectedItem($event, expense, generateTextId('card-expense-' + expense.id))"
																	/>
																</div>
																<div
																	class="second-col"
																	:style="`width: ${
																		notSubmittedState && parseInt(expense.validated, 10) === 0 ? '56.5%;' : '67.5%;'
																	}`"
																>
																	<div class="description" v-if="showExpenseTitle(expense)">
																		{{ expense.description }}
																	</div>
																	<div class="title">#{{ expense.id }}</div>
																	<div class="amount">
																		{{ rendAmountTotal(expense.amountTotal) }}
																	</div>
																	<div class="date">
																		{{ dateToLiteral(expense.date) }}
																	</div>
																	<div class="carbon" v-if="expense.type !== 2">
																		<div class="icon">
																			<component :is="getLucideIcon('Sprout')" color="#47C7BF" :size="14" />
																		</div>
																		<div class="label">
																			{{ rendCo2(expense.kgCoTwo) }}<span> CO<sub>2</sub></span>
																		</div>
																	</div>
																	<div class="mileage-resume" v-else>
																		<div class="details pt-1" v-if="expense.fromLocation && expense.toLocation">
																			<div class="first-col">
																				<div class="icon-rotate">
																					<component
																						:is="getLucideIcon('ArrowLeftRight')"
																						color="#47C7BF"
																						:size="16"
																					/>
																				</div>
																			</div>
																			<div class="second-col">
																				<div>{{ expense.fromLocation }}</div>
																				<div>{{ expense.toLocation }}</div>
																			</div>
																		</div>
																		<div class="details" :class="`${!expense.km ? '' : 'pt-2'}`">
																			<div class="first-col">
																				<div class="icon">
																					<component
																						:is="getLucideIcon('Sprout')"
																						color="#47C7BF"
																						:size="`${!expense.km ? 14 : 16}`"
																					/>
																				</div>
																			</div>
																			<div class="second-col" :class="`${!expense.km ? 'spl-4' : ''}`">
																				<div v-if="expense.km">
																					{{ recalculateDistanceByUnit(expense.km) }} {{ distanceUnit }}
																				</div>
																				<div :style="`margin-bottom: ${expense.km ? '0' : '-1px'}`">
																					{{ rendCo2(expense.kgCoTwo) }} CO<sub>2</sub>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="third-col">
																	<div class="status-wrapper">
																		<div :class="`status ${expense.statusClass}`" v-if="!isFilmSingle">
																			{{ expense.validatedStatus }}
																		</div>
																		<div :class="`${!isFilmSingle ? 'type mt-2' : 'type'}`">
																			<span class="icon">
																				<!-- #526878D6 -->
																				<component
																					:is="getLucideIcon('Ticket')"
																					color="#5B6164D6"
																					:size="16"
																					v-if="expense.type === 0"
																				/>
																				<!-- ticket -->
																				<component
																					:is="getLucideIcon('FileText')"
																					color="#5B6164D6"
																					:size="14"
																					v-if="expense.type === 1"
																				/>
																				<!-- invoice -->
																				<component
																					:is="getLucideIcon('Car')"
																					color="#5B6164D6"
																					:size="16"
																					v-if="expense.type === 2"
																				/>
																				<!-- mileage -->
																			</span>
																			<span class="label" :style="`${expense.type === 2 ? 'margin-top: 2px;' : ''}`">{{
																				expense.typeString
																			}}</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="footer">
															<div class="actions">
																<div
																	class="first-col"
																	v-if="notSubmittedState && expense.images.length > 0"
																	@click="showPicture(expense.images)"
																>
																	<div class="icon">
																		<component :is="getLucideIcon('FileImage')" color="#06263E" :size="16" />
																	</div>
																	<div class="label" style="margin-top: 1px">{{ FormMSG(28, 'See Receipt') }}</div>
																</div>
																<div
																	class="second-col"
																	:style="`width: ${
																		(notSubmittedState && expense.images.length === 0) || submittedState ? '100%;' : '60%;'
																	}`"
																>
																	<div
																		class="cursor-pointer d-flex flex-row pr-3"
																		v-if="notSubmittedState"
																		@click="editExpenseMobile(expense)"
																	>
																		<div class="icon">
																			<component :is="getLucideIcon('Edit')" color="#06263E" :size="16" />
																		</div>
																		<div class="label">{{ FormMSG(29, 'Edit') }}</div>
																	</div>
																	<div
																		class="cursor-pointer d-flex flex-row"
																		v-if="submittedState"
																		@click="viewExpenseDatailMobile(expense)"
																	>
																		<div class="icon">
																			<component :is="getLucideIcon('Eye')" color="#06263E" :size="16" />
																		</div>
																		<div class="label">{{ FormMSG(33, 'View') }}</div>
																	</div>
																	<div
																		class="cursor-pointer d-flex flex-row"
																		v-if="notSubmittedState"
																		@click="deleteExpenseMobile(expense.id)"
																	>
																		<div class="icon">
																			<component :is="getLucideIcon('Trash')" color="#06263E" :size="16" />
																		</div>
																		<div class="label">{{ FormMSG(32, 'Delete') }}</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div v-else class="empty-data">
													{{ FormMSG(18, 'No expense found') }}
												</div>
												<b-row class="footer-fixed" v-if="notSubmittedState && notSubmittedIds.length > 0">
													<b-col cols="12">
														<b-button block size="lg" variant="primary" class="mb-2" @click="handleSendExpensesForValidationMobile">
															<span v-if="!isFilmSingle">{{ FormMSG(34, 'Send Expenses for Validation') }}</span>
															<span v-else>{{ FormMSG(36, 'Create expenses sheet') }}</span>
														</b-button>
													</b-col>
												</b-row>
											</div>
										</div>
									</div>
									<b-card-text v-else>
										<div v-if="notSubmitedExpenses.length > 0">
											<b-row class="d-flex align-items-center pb-0 pt-3" align-h="between">
												<b-col md="6" class="pl-4">
													<b-form-group class="mr-3">
														<div class="fw-400" style="font-size: 13px; color: rgba(6, 38, 62, 0.65)">
															*{{ FormMSG(8, 'Click on a row to view more informations') }}
														</div>
														<div v-show="isActiveSelectAll && !isSelectAll">
															<b-form-checkbox
																size="lg"
																id="select-all-item"
																v-model="isSelectAll"
																:value="true"
																:unchecked-value="false"
																@change="setIsSelectAll"
																class="pj-cb"
															>
																<div style="margin-top: 1px">{{ FormMSG(51, 'Select all') }}</div>
															</b-form-checkbox>
														</div>
														<div v-show="isActiveSelectAll && isSelectAll">
															<b-form-checkbox
																v-model="isSelectAll"
																:value="true"
																:unchecked-value="false"
																size="lg"
																id="select-all-item-none"
																class="pj-cb"
																@change="setIsSelectAll"
															>
																<div style="margin-top: 1px">{{ FormMSG(50, 'Unselect All') }}</div>
															</b-form-checkbox>
														</div>
													</b-form-group>
												</b-col>
												<b-col md="6" class="text-right pb-3">
													<b-button
														variant="primary"
														@click="handSendAllExpensesValidation"
														:disabled="itemsSelected.length !== 0 ? false : true"
													>
														{{ !isFilmSingle ? FormMSG(7, 'Send expenses for validation') : FormMSG(63, 'Create expense sheet') }}
													</b-button>
												</b-col>
											</b-row>
											<b-table
												id="table-draft"
												:hover="hover"
												responsive="sm"
												:items="notSubmitedExpenses"
												style="text-align: left"
												:fields="notSubmittedTableFields"
												:current-page="notSubmitedYetTableCurrentPage"
												:per-page="perPage"
												sticky-header="700px"
												bordered
												@row-clicked="openEditExpense"
												small
												:head-variant="hv"
												:empty-text="FormMSG(163, 'No expense found')"
												show-empty
											>
												<template #cell(select)="data">
													<span v-show="data.item.validated !== 16 && data.item.validated !== 4">
														<b-form-checkbox
															v-if="+data.item.amountTotal !== 0"
															:id="`expense-efc-selected-${data.item.id + Math.random()}`"
															:name="`expEfcSelected${data.item.id}`"
															v-model="data.item.isSelected"
															@change="handleChangeExpSelected($event, data.item)"
															size="lg"
															class="pj-cb pb-1"
														/>
													</span>
												</template>
												<template #cell(validatedStatus)="data">
													<div class="wrap-status">
														<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
															{{ data.item.validatedStatus }}
														</div>
													</div>
												</template>
												<template #cell(encoder)="data">
													<div class="wrap-status">
														<div>{{ data.item.encoder.name }} {{ data.item.encoder.firstName }}</div>
													</div>
												</template>
												<template #cell(pict)="data">
													<b-button
														v-if="data.item.images.length > 0"
														variant="success"
														size="sm"
														@click="showPicture(data.item.images)"
														class="btn bg-transparent border-0"
													>
														<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
													</b-button>
												</template>
												<template #cell(rem)="data">
													<component
														:is="getLucideIcon(ICONS.TRASH.name)"
														:color="ICONS.TRASH.color"
														:size="20"
														@click.prevent.stop="handleDeleteExpense(data.item)"
													/>
												</template>
											</b-table>
										</div>
										<div v-if="notSubmitedExpenses.length < 1" class="empty-state">
											{{ FormMSG(99, 'You have no expense items yet') }}
										</div>
										<b-row v-if="notSubmitedExpenses.length > 0">
											<b-col>
												<b-button
													variant="custom-outline-gray"
													class="w-138-px"
													:disabled="itemsSelected.length === 0"
													@click="handleDeleteSelected"
												>
													{{ FormMSG(590, 'Delete') }}
												</b-button>
											</b-col>
										</b-row>
									</b-card-text>
								</b-tab>
								<b-tab lazy :title="tabTwoCaption()">
									<div v-if="$screen.width <= 576" class="animated fadeIn pr-1 pl-1">
										<div class="container-mobile">
											<div class="my-expense-mobile">
												<div class="content pb-3" v-if="Expenses.submited.length > 0">
													<div
														class="card-expense"
														v-for="expense in Expenses.submited"
														:key="expense.id"
														:id="`${parseInt(expense.validated, 10) === 0 ? generateTextId('card-expense-' + expense.id) : ''}`"
													>
														<div class="wrapper">
															<div class="resume">
																<div
																	class="second-col"
																	:style="`width: ${parseInt(expense.validated, 10) === 0 ? '56.5%;' : '67.5%;'}`"
																>
																	<div class="description" v-if="showExpenseTitle(expense)">
																		{{ expense.description }}
																	</div>
																	<div class="title">#{{ expense.id }}</div>
																	<div class="amount">
																		{{ rendAmountTotal(expense.amountTotal) }}
																	</div>
																	<div class="date">
																		{{ dateToLiteral(expense.date) }}
																	</div>
																	<div class="carbon" v-if="expense.type !== 2">
																		<div class="icon">
																			<component :is="getLucideIcon('Sprout')" color="#47C7BF" :size="14" />
																		</div>
																		<div class="label">
																			{{ rendCo2(expense.kgCoTwo) }}<span> CO<sub>2</sub></span>
																		</div>
																	</div>
																	<div class="mileage-resume" v-else>
																		<div class="details pt-1" v-if="expense.fromLocation && expense.toLocation">
																			<div class="first-col">
																				<div class="icon-rotate">
																					<component
																						:is="getLucideIcon('ArrowLeftRight')"
																						color="#47C7BF"
																						:size="16"
																					/>
																				</div>
																			</div>
																			<div class="second-col">
																				<div>{{ expense.fromLocation }}</div>
																				<div>{{ expense.toLocation }}</div>
																			</div>
																		</div>
																		<div class="details" :class="`${!expense.km ? '' : 'pt-2'}`">
																			<div class="first-col">
																				<div class="icon">
																					<component
																						:is="getLucideIcon('Sprout')"
																						color="#47C7BF"
																						:size="`${!expense.km ? 14 : 16}`"
																					/>
																				</div>
																			</div>
																			<div class="second-col" :class="`${!expense.km ? 'spl-4' : ''}`">
																				<div v-if="expense.km">
																					{{ recalculateDistanceByUnit(expense.km) }} {{ distanceUnit }}
																				</div>
																				<div :style="`margin-bottom: ${expense.km ? '0' : '-1px'}`">
																					{{ rendCo2(expense.kgCoTwo) }} CO<sub>2</sub>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="third-col">
																	<div class="status-wrapper">
																		<div :class="`status ${expense.statusClass}`" v-if="!isFilmSingle">
																			{{ expense.validatedStatus }}
																		</div>
																		<div :class="`${!isFilmSingle ? 'type mt-2' : 'type'}`">
																			<span class="icon">
																				<!-- #526878D6 -->
																				<component
																					:is="getLucideIcon('Ticket')"
																					color="#5B6164D6"
																					:size="16"
																					v-if="expense.type === 0"
																				/>
																				<!-- ticket -->
																				<component
																					:is="getLucideIcon('FileText')"
																					color="#5B6164D6"
																					:size="14"
																					v-if="expense.type === 1"
																				/>
																				<!-- invoice -->
																				<component
																					:is="getLucideIcon('Car')"
																					color="#5B6164D6"
																					:size="16"
																					v-if="expense.type === 2"
																				/>
																				<!-- mileage -->
																			</span>
																			<span class="label" :style="`${expense.type === 2 ? 'margin-top: 2px;' : ''}`">{{
																				expense.typeString
																			}}</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="footer">
															<div class="actions">
																<div class="second-col" :style="`width: ${submittedState ? '100%;' : '60%;'}`">
																	<div class="cursor-pointer d-flex flex-row" @click="viewExpenseDatailMobile(expense)">
																		<div class="icon">
																			<component :is="getLucideIcon('Eye')" color="#06263E" :size="16" />
																		</div>
																		<div class="label">{{ FormMSG(33, 'View') }}</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div v-else class="empty-data">
													{{ FormMSG(18, 'No expense found') }}
												</div>
												<b-row class="footer-fixed" v-if="notSubmittedState && notSubmittedIds.length > 0">
													<b-col cols="12">
														<b-button block size="lg" variant="primary" class="mb-2" @click="handleSendExpensesForValidationMobile">
															<span v-if="!isFilmSingle">{{ FormMSG(34, 'Send Expenses for Validation') }}</span>
															<span v-else>{{ FormMSG(36, 'Create expenses sheet') }}</span>
														</b-button>
													</b-col>
												</b-row>
											</div>
										</div>
									</div>
									<b-card-text v-else>
										<b-row>
											<b-col cols="6" class="pb-3">
												<b-input-group>
													<b-form-input v-model="filter" type="text" :placeholder="FormMSG(31, 'Type to Search')" />
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<component
																:is="getLucideIcon(ICONS.SEARCH.name)"
																:color="ICONS.SEARCH.color"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																v-if="filter.length === 0"
															/>
															<component
																:is="getLucideIcon(ICONS.X.name)"
																:color="ICONS.X.color"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																@click="filter = ''"
																v-else
															/>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-col>
										</b-row>
										<b-table
											:hover="hover"
											responsive="sm"
											:filter="filter"
											:items="Expenses.submited"
											:fields="submittedTableFields"
											:current-page="currentPage"
											:per-page="0"
											bordered
											@row-clicked="rowClicked"
											small
											sticky-header="700px"
											:head-variant="hv"
											:empty-text="FormMSG(163, 'No expense found')"
											show-empty
										>
											<!-- :tbody-tr-class="`table-large-body`" -->
											<template #cell(validatedStatus)="data">
												<div class="wrap-status">
													<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
														{{ data.item.validatedStatus }}
													</div>
												</div>
											</template>
											<template #cell(encoder)="data">
												<div class="wrap-status">
													<div>{{ data.item.encoder.name }} {{ data.item.encoder.firstName }}</div>
												</div>
											</template>
										</b-table>
									</b-card-text>
								</b-tab>
								<b-tab lazy v-if="!isFilmSingle" :title="FormMSG(35265, 'Approved')">
									<div v-if="$screen.width <= 576" class="animated fadeIn">
										<div class="container-mobile">
											<div class="my-expense-mobile">
												<div class="content pb-3" v-if="Expenses.approved.length > 0">
													<div
														class="card-expense"
														v-for="expense in Expenses.approved"
														:key="expense.id"
														:id="`${parseInt(expense.validated, 10) === 0 ? generateTextId('card-expense-' + expense.id) : ''}`"
													>
														<div class="wrapper">
															<div class="resume">
																<div
																	class="second-col"
																	:style="`width: ${parseInt(expense.validated, 10) === 0 ? '56.5%;' : '67.5%;'}`"
																>
																	<div class="description" v-if="showExpenseTitle(expense)">
																		{{ expense.description }}
																	</div>
																	<div class="title">#{{ expense.id }}</div>
																	<div class="amount">
																		{{ rendAmountTotal(expense.amountTotal) }}
																	</div>
																	<div class="date">
																		{{ dateToLiteral(expense.date) }}
																	</div>
																	<div class="carbon" v-if="expense.type !== 2">
																		<div class="icon">
																			<component :is="getLucideIcon('Sprout')" color="#47C7BF" :size="14" />
																		</div>
																		<div class="label">
																			{{ rendCo2(expense.kgCoTwo) }}<span> CO<sub>2</sub></span>
																		</div>
																	</div>
																	<div class="mileage-resume" v-else>
																		<div class="details pt-1" v-if="expense.fromLocation && expense.toLocation">
																			<div class="first-col">
																				<div class="icon-rotate">
																					<component
																						:is="getLucideIcon('ArrowLeftRight')"
																						color="#47C7BF"
																						:size="16"
																					/>
																				</div>
																			</div>
																			<div class="second-col">
																				<div>{{ expense.fromLocation }}</div>
																				<div>{{ expense.toLocation }}</div>
																			</div>
																		</div>
																		<div class="details" :class="`${!expense.km ? '' : 'pt-2'}`">
																			<div class="first-col">
																				<div class="icon">
																					<component
																						:is="getLucideIcon('Sprout')"
																						color="#47C7BF"
																						:size="`${!expense.km ? 14 : 16}`"
																					/>
																				</div>
																			</div>
																			<div class="second-col" :class="`${!expense.km ? 'spl-4' : ''}`">
																				<div v-if="expense.km">
																					{{ recalculateDistanceByUnit(expense.km) }} {{ distanceUnit }}
																				</div>
																				<div :style="`margin-bottom: ${expense.km ? '0' : '-1px'}`">
																					{{ rendCo2(expense.kgCoTwo) }} CO<sub>2</sub>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="third-col">
																	<div class="status-wrapper">
																		<div :class="`status ${expense.statusClass}`" v-if="!isFilmSingle">
																			{{ expense.validatedStatus }}
																		</div>
																		<div :class="`${!isFilmSingle ? 'type mt-2' : 'type'}`">
																			<span class="icon">
																				<!-- #526878D6 -->
																				<component
																					:is="getLucideIcon('Ticket')"
																					color="#5B6164D6"
																					:size="16"
																					v-if="expense.type === 0"
																				/>
																				<!-- ticket -->
																				<component
																					:is="getLucideIcon('FileText')"
																					color="#5B6164D6"
																					:size="14"
																					v-if="expense.type === 1"
																				/>
																				<!-- invoice -->
																				<component
																					:is="getLucideIcon('Car')"
																					color="#5B6164D6"
																					:size="16"
																					v-if="expense.type === 2"
																				/>
																				<!-- mileage -->
																			</span>
																			<span class="label" :style="`${expense.type === 2 ? 'margin-top: 2px;' : ''}`">{{
																				expense.typeString
																			}}</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="footer">
															<div class="actions">
																<div class="second-col" :style="`width: ${submittedState ? '100%;' : '60%;'}`">
																	<div class="cursor-pointer d-flex flex-row" @click="viewExpenseDatailMobile(expense)">
																		<div class="icon">
																			<component :is="getLucideIcon('Eye')" color="#06263E" :size="16" />
																		</div>
																		<div class="label">{{ FormMSG(33, 'View') }}</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div v-else class="empty-data">
													{{ FormMSG(18, 'No expense found') }}
												</div>
												<b-row class="footer-fixed" v-if="notSubmittedState && notSubmittedIds.length > 0">
													<b-col cols="12">
														<b-button block size="lg" variant="primary" class="mb-2" @click="handleSendExpensesForValidationMobile">
															<span v-if="!isFilmSingle">{{ FormMSG(34, 'Send Expenses for Validation') }}</span>
															<span v-else>{{ FormMSG(36, 'Create expenses sheet') }}</span>
														</b-button>
													</b-col>
												</b-row>
											</div>
										</div>
									</div>
									<b-card-text v-else>
										<b-row>
											<b-col cols="6" class="pb-3">
												<b-input-group>
													<b-form-input v-model="filter" type="text" :placeholder="FormMSG(31, 'Type to Search')" />
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<component
																:is="getLucideIcon(ICONS.SEARCH.name)"
																:color="ICONS.SEARCH.color"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																v-if="filter.length === 0"
															/>
															<component
																:is="getLucideIcon(ICONS.X.name)"
																:color="ICONS.X.color"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																@click="filter = ''"
																v-else
															/>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-col>
										</b-row>
										<b-table
											:hover="hover"
											responsive="sm"
											:filter="filter"
											:items="Expenses.approved"
											:fields="submittedTableFields"
											:current-page="currentPage"
											:per-page="0"
											bordered
											@row-clicked="rowClicked"
											small
											sticky-header="700px"
											:head-variant="hv"
											:empty-text="FormMSG(163, 'No expense found')"
											show-empty
										>
											<!-- :tbody-tr-class="`table-large-body`" -->
											<template #cell(validatedStatus)="data">
												<div class="wrap-status">
													<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
														{{ data.item.validatedStatus }}
													</div>
												</div>
											</template>
											<template #cell(encoder)="data">
												<div class="wrap-status">
													<div>{{ data.item.encoder.name }} {{ data.item.encoder.firstName }}</div>
												</div>
											</template>
										</b-table>
									</b-card-text>
								</b-tab>
							</b-tabs>
						</b-card>
					</div>
				</b-row>
				<!-- <div v-if="showSendValidation()" class="btn-custom">
					<b-button class="hide-on-desktop" variant="primary" @click="handSendAllExpensesValidation">
						<div v-if="!isFilmSingle">
							<span>{{ FormMSG(97, 'Send expenses') }}</span
							>&nbsp;
							<span>{{ FormMSG(98, 'for validation') }}</span>
						</div>
						<div v-else>
							<span>{{ FormMSG(100, 'Create expense sheet') }}</span>
						</div>
					</b-button>
				</div> -->

				<ExpenseService
					:key="expenseModalRefreshToken"
					v-model="isNewExpenseModalOpen"
					:expense-type="newExpenseType"
					:is-new="isNew"
					:edit-data="currEditExpense"
					@expense-service-main:error="getError"
					@submited="handleModalSubmited"
					@expense-form-component:close="onCloseExpenseForm"
				/>
				<title-expense-modal
					v-model="isOpenTitleModalPC"
					:reinitialize-value="reinitializeTitle"
					@title-expense-modal:cancel="onCancelTitleModal"
					@title-expense-modal:add="onAddTitleModalPC"
				/>
				<title-expense-modal
					v-model="isOpenTitleModal"
					:reinitialize-value="reinitializeTitle"
					@title-expense-modal:cancel="onCancelTitleModal"
					@title-expense-modal:add="onAddTitleModal"
				/>
			</div>
			<KlippaOneTicketFromPicture
				v-model="isKlippaOcrModalOpen"
				:key="klippaModalRefreshKey"
				:multiple-doc="isKlippaOcrMultipleDocument"
				:split-tva="isTvaSplitting"
				@submited="handleModalSubmited"
				@closed="handleKlippaModalRefreshKey"
			/>
		</div>
	</errorContainer>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import { generateSecureId } from '@/shared/utils';
import { isNil, omit, makeID } from '~utils';
import { rendCurrency, rendKgCo2, formatTableDate } from '~helpers';
import { getFileExtension } from '@/shared/helpers';
import mapProps from '@/shared/vuePropsMapper';
import { recalculateDistanceValueByUnit } from '@/shared/google/helpers';

import ExpenseService from '@/components/ExpenseService/Main';
import KlippaOneTicketFromPicture from '@/components/ExpenseService/KlippaOneTicketFromPicture';
import MyExpensesMobileView from '@/components/MobileViews/MyExpense/Main';
import WalletIconSVG from '@/components/icons/wallet';
import InvoiceIconSVG from '@/components/icons/invoice';
import CarMileageIconSVG from '@/components/icons/car-mileage';
import { sendSelectedExpensesForValidation, sendAllExpensesForValidation } from '@/cruds/expense.crud';
import TitleExpenseModal from './TitleExpenseModal';
import { store } from '@/store';

import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import ValidateExpenses from '../validateExp/ValidateExpenses.vue';
import { getValidations } from '../../cruds/validations.crud';
import _ from 'lodash';

export default {
	name: 'MyExpenses',
	components: {
		Loading,
		ExpenseService,
		WalletIconSVG,
		InvoiceIconSVG,
		CarMileageIconSVG,
		MyExpensesMobileView,
		TitleExpenseModal,
		ValidateExpenses,
		KlippaOneTicketFromPicture
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		...mapProps(['hover', 'striped', 'bordered'], {
			type: Boolean,
			default: true
		}),
		...mapProps(['small', 'fixed'], {
			type: Boolean,
			default: false
		}),
		caption1: {
			type: String,
			default: 'My Expenses'
		}
	},
	data() {
		return {
			currentExpenseTab: 0,
			klippaModalRefreshKey: makeID(10),
			hv: 'dark',
			Validated: 0,
			erreur: {},
			canSeeApprove: true,
			currentValidationLevel: 0,
			//   Expenses: [],
			notSubmitedYetTableCurrentPage: 1,
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			isLoading: false,
			activeListTab: 0,
			filter: '',
			isCaptureModalOpen: false,
			curExp: {
				id: 0,
				date: '',
				type: 0,
				amountTotal: 0,
				validated: 0,
				validatedStatus: 'Not Submited',
				typeString: ''
			},
			currEditExpense: null,
			// CREATE NEW EXPENSES MODAL CONTAINER
			isNewExpenseModalOpen: false,
			newExpenseType: 2, // default km mileage
			expenseModalRefreshToken: 0,
			isSubmitted: false,
			notSubmittedState: true,
			submittedState: false,
			expenses: [],
			notSubmittedIds: [],
			notSubmittedCopyIds: [],
			checkAll: true,
			itemsSelected: [],
			isOpenTitleModalPC: false,
			isOpenTitleModal: false,
			reinitializeTitle: false,
			isNew: false,
			isSelectAll: false,
			notSubmitedExpenses: [],
			project: {},
			isActiveSelectAll: false,
			isKlippaOcrModalOpen: false,
			isKlippaOcrMultipleDocument: false,
			isTvaSplitting: false,
			showModalWarningItemNumber: false,
			submittedExp: [],
			maxItemSelected: {
				ticket: 0,
				travel: 0,
				invoice: 0
			},
			msgWarnigMaxItemSelected: ''
		};
	},
	watch: {
		isActiveSelectAll: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		notSubmitedExpenses: {
			handler(newArrays) {
				this.isActiveSelectAll = newArrays.some((item) => +item.amountTotal !== 0 && +item.validated !== 4 && +item.validated !== 16);
				return newArrays;
			},
			immediate: true,
			deep: true
		},
		'$route.query.activeTab': {
			handler(newVal) {
				setTimeout(() => {
					if (newVal) {
						this.currentExpenseTab = newVal;
					} else {
						this.currentExpenseTab = 0;
					}
				}, 300);
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		...mapGetters({
			Expenses: 'expenses/expenses',
			expensesCount: 'expenses/expensesCount',
			notSubmitedExpensesStore: 'expenses/notSubmitedExpenses',
			expensesActiveTab: 'expenses/activeTab',
			collectionId: 'expenses/collectionId'
		}),

		checkWithMaxExpenseItems() {
			return store.state.myProject.maxExpenseItemsPerSheet > 0;
		},
		submittedTableFields() {
			if (this.isFilmSingle) {
				const keys = ['validatedStatus'];
				return this.submittedTableFieldsBase.filter((t) => !keys.includes(t.key));
			} else {
				return this.submittedTableFieldsBase;
			}
		},
		encodedByField() {
			if (!this.isFilmSingle) {
				return { key: 'encoder', label: 'Encoded by', sortable: true, class: 'text-center' };
			}
		},
		submittedTableFieldsBase() {
			const _t = this.FormMSG;
			return [
				{
					key: 'id',
					label: '#' + _t(78781, 'Id'),
					formatter: (v) => `${v}`,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'validatedStatus',
					label: _t(78789, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				this.encodedByField,
				{
					key: 'typeString',
					label: _t(78782, 'Type'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'description',
					label: _t(78784, 'Description'),
					formatter: (v) => v,
					sortable: true
				},
				{
					key: 'date',
					label: _t(4451, 'Date'),
					formatter: (v) => {
						return new moment(v).format('DD/MM/YYYY HH:mm');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountTotal',
					label: _t(4452, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				}
			].filter((item) => item !== undefined);
		},
		notSubmittedTableFields() {
			if (this.isFilmSingle) {
				const keys = ['validatedStatus'];
				return this.notSubmittedTableFieldsBase.filter((t) => !keys.includes(t.key));
			} else {
				return this.notSubmittedTableFieldsBase;
			}
		},
		notSubmittedTableFieldsBase() {
			const _t = this.FormMSG;
			return [
				{
					key: 'select',
					label: _t(165, 'Select'),
					class: 'text-center'
				},
				{
					label: '#' + _t(78781, 'Id'),
					formatter: (v) => `${v}`,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'validatedStatus',
					label: _t(78789, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				this.encodedByField,
				{
					key: 'typeString',
					label: _t(78782, 'Type'),
					formatter: (v) => v,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'description',
					label: _t(78784, 'Description'),
					formatter: (v) => v,
					sortable: true
				},
				{
					key: 'date',
					label: _t(4462, 'Date'),
					formatter: (v) => formatTableDate(v),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'departmentName',
					label: _t(4463, 'Department'),
					formatter: (v) => v.toLowerCase(),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountTotal',
					label: _t(4465, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountVat',
					label: _t(23, 'VAT'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'pict',
					label: _t(4466, 'Picture'),
					formatter: (v) => v,
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'rem',
					label: _t(4467, 'Delete'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			].filter((item) => item !== undefined);
		},
		rendConfirmModalTxt() {
			const _t = this.FormMSG;
			return {
				title: _t(56561, 'Please Confirm'),
				okTitle: _t(56562, 'YES'),
				cancelTitle: _t(56563, 'NO')
			};
		},
		captionCheckAll() {
			return this.checkAll ? this.FormMSG(17, 'Select All') : this.FormMSG(35, 'Unselect All');
		},
		firstTabCaptionMobile() {
			if (!this.isFilmSingle) {
				return this.FormMSG(15, 'Not Submitted');
			} else {
				return this.FormMSG(37, 'My expense items');
			}
		},
		secondTabCaptionMobile() {
			if (!this.isFilmSingle) {
				return this.FormMSG(16, 'Submitted');
			} else {
				return this.FormMSG(38, 'Expense sheets');
			}
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},
	created() {
		this.getRoleValidation();
		const home = `/myexpenses`;

		this.initData();

		const { form, action, type, back, s, ec, eu } = this.$route.query;
		if (!isNil(action) && !isNil(type)) {
			let path = `/myexpenses/${form}/${action}/${type}`;
			if (!isNil(back)) {
				path += `?back=${back}`;
			}
			this.$router.push({ path });
		}

		// s === 0 => add
		// s === 1 => update
		// s === 2 => delete
		if (!isNil(s)) {
			if (+s === 0) {
				if (+type === 0) {
					this.createToastForMobile(this.FormMSG(43, 'Success'), this.FormMSG(339, 'Expense added successfully'), '');
				} else if (+type === 2) {
					this.createToastForMobile(this.FormMSG(43, 'Success'), this.FormMSG(340, 'Travel added successfully'), '');
				}
			} else if (+s === 1) {
				if (+type === 0) {
					this.createToastForMobile(this.FormMSG(43, 'Success'), this.FormMSG(44, 'Expense updated successfully'), '');
				} else if (+type === 2) {
					this.createToastForMobile(this.FormMSG(43, 'Success'), this.FormMSG(341, 'Travel updated successfully'), '');
				}
			}
		}

		if (!isNil(ec)) {
			let message = '';
			if (ec === '509') {
				if (!isNil(eu) && eu === '1') {
					message = this.FormMSG(
						129,
						'Sorry, cannot perfrom this action because your contract will be expired at this date, please contact your administrator.'
					);
				} else {
					message = this.FormMSG(127, 'Sorry, cannot perfrom this action because your contract has been expired, please contact your administrator.');
				}
			} else {
				message = this.FormMSG(128, 'An error was occured during create an expense, please contact your administrator.');
			}

			this.createToastForMobile(this.FormMSG(125, 'Error'), message, '', 'danger');

			this.$router.push({ home });
		}

		this.project = store.state.myProject;
	},
	mounted() {
		if (this.$route.query.activeSecondTab) {
			this.notSubmittedState = false;
			this.notSubmittedState = true;
		}

		this.reloadData();

		this.$bus.$on('filepreviewer:image-deleted-from-parent', () => this.initData());
	},
	methods: {
		...mapActions({
			getExpenses: 'expenses/getExpenses',
			deleteExpense: 'expenses/deleteExpense',
			sendToValidation: 'expenses/sendToValidation',
			setFocusExpenseItem: 'expenses/setFocusExpenseItem',
			getCurExpense: 'expenses/getCurExpense',
			getNotSubmittedExpense: 'expenses/getNotSubmittedExpense',
			handleTabChanged: 'expenses/setActiveTab',
			setCurrentExpenseMobile: 'expenses/setCurrentExpenseMobile'
		}),
		showSendValidation() {
			if (
				(this.checkAll && this.currentExpenseTab === 0 && !this.canSeeApprove) ||
				(this.checkAll && this.currentExpenseTab === 1 && this.canSeeApprove)
			) {
				return true;
			}
			return false;
		},
		handleKlippaModalRefreshKey() {
			this.klippaModalRefreshKey = makeID(10);
		},
		async handleDeleteSelected() {
			const action = async () => {
				for (let i = 0; i < this.itemsSelected.length; i++) {
					await this.handleDeleteExpense(this.itemsSelected[i], false);
				}

				this.itemsSelected = [];
				this.createToastForMobile(this.FormMSG(854, 'Success'), this.FormMSG(589, 'Deleted successfully!'));
			};

			this.confirmModal(action, this.FormMSG(874, 'Are you sure ?'));
		},
		async getRoleValidation() {
			const validations = await getValidations(0);
			const userId = store.userID();
			const departmentId = store.state.myProfile.department;

			let haveValidationAllDepartment = null;
			let haveValidationSingleDepartment = null;

			for (let i = 0; i < validations.length; i++) {
				const element = validations[i];
				if (parseInt(element.department, 10) === -1 && parseInt(element.user.id, 10) === userId && element.type == 0) {
					haveValidationAllDepartment = element;
				} else if (parseInt(element.user.id, 10) === userId && parseInt(element.department, 10) === departmentId && element.type == 0) {
					haveValidationSingleDepartment = element;
				}
			}
			// console.log(haveValidationAllDepartment);
			if (!isNil(haveValidationSingleDepartment) && !isNil(haveValidationAllDepartment)) {
				if (haveValidationSingleDepartment.validationLevelExp > haveValidationAllDepartment.validationLevelExp) {
					this.currentValidationLevel = haveValidationSingleDepartment.validationLevelExp;
				} else {
					this.currentValidationLevel = haveValidationAllDepartment.validationLevelExp;
				}
			} else {
				if (!isNil(haveValidationSingleDepartment)) {
					this.currentValidationLevel = haveValidationSingleDepartment.validationLevelExp;
				} else if (!isNil(haveValidationAllDepartment)) {
					this.currentValidationLevel = haveValidationAllDepartment.validationLevelExp;
				}
			}

			if ((this.currentValidationLevel == 2 || this.currentValidationLevel == 1) && !this.isFilmSingle) {
				this.canSeeApprove = true;
			} else {
				this.canSeeApprove = false;
			}
			// alert(this.canSeeApprove);
		},
		async initData() {
			// if (this.$route.query.activeSecondTab) {
			// 	this.currentExpenseTab = 1;
			// }
			// if (this.$route.query.validate) {
			// 	this.currentExpenseTab = 0;
			// }
			await this.getNotSubmittedExpense();
			await this.reloadData();
			this.notSubmittedCopyIds = this.collectionId;
			// this.changeBtnAction();
			if (this.notSubmittedState) {
				this.expenses = this.notSubmitedExpenses;
			} else if (this.submittedState) {
				this.expenses = this.Expenses.submited;
			}
		},
		changeBtnAction() {
			switch (this.expensesActiveTab) {
				case 0:
					this.notSubmittedState = true;
					this.submittedState = false;
					this.expenses = this.notSubmitedExpenses;

					break;
				case 1:
					this.notSubmittedState = false;
					this.submittedState = true;
					this.expenses = this.Expenses.submited;

					break;
			}
		},
		handleTicketPayloadRefreshed(payload) {
			this.currEditExpense = _.merge(this.currEditExpense, omit(['id'], payload));
		},
		/**
		 * @param {Object<{Boolean,Boolean}>} { isMultipe, isSplitTva }
		 */
		handleCreateKlippaOcrOpenModal({ isMultipe, isSplitTva }) {
			this.isKlippaOcrMultipleDocument = isMultipe || false;
			this.isTvaSplitting = isSplitTva || false;
			this.isKlippaOcrModalOpen = true;
		},
		tabOneCaption() {
			return this.isFilmSingle ? this.FormMSG(123, 'My expense items') : this.FormMSG(1230, 'Drafts');
		},
		tabTwoCaption() {
			return this.isFilmSingle ? this.FormMSG(121, 'Expense sheets') : this.FormMSG(2100, 'Pending');
		},
		async handleModalSubmited() {
			this.expenseModalRefreshToken = this.expenseModalRefreshToken + 1;
			if (this.canSeeApprove && this.$screen.width >= 576) {
				this.currentExpenseTab = 1;
			} else {
				this.currentExpenseTab = 0;
			}
			this.$toast({
				message: this.FormMSG(4, 'Your expense was saved successfully.'),
				title: this.FormMSG(162, 'Success'),
				variant: 'success'
			});
			this.isNewExpenseModalOpen = false;
			this.klippaModalRefreshKey = makeID(10);
			this.initData();
		},
		async reloadData() {
			this.maxItemSelected.ticket = 0;
			this.maxItemSelected.travel = 0;
			this.maxItemSelected.invoice = 0;

			await this.getExpenses();
			this.notSubmitedExpenses = this.notSubmitedExpensesStore;
		},
		/**
		 * @param {Object} expense
		 */
		openEditExpense(expense) {
			if (expense.type === 2) {
				expense.originalKm = expense.km;
				expense.originalFromLocation = expense.fromLocation;
				expense.originalToLocation = expense.toLocation;
			} else {
				expense.originalImages = expense.images;
			}
			this.newExpenseType = expense.type;
			this.currEditExpense = expense;
			this.isNewExpenseModalOpen = true;
			this.isNew = false;
		},

		/**
		 * @param {Array} images
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		},
		tsDetailLink(id) {
			return `/myexpenses/${id.toString()}`;
		},
		/**
		 * @param {Object} item - expenseClicked
		 */
		rowClicked(item) {
			this.getCurExpense(parseInt(item.id, 10));
			this.setFocusExpenseItem(item);
			const tsLink = this.tsDetailLink(item.id);
			this.$router.push({
				path: tsLink,
				query: {
					previousActiveTab: this.currentExpenseTab
				}
			});
			// this.$router.push({ path: tsLink });
		},
		viewExpenseDatailMobile(item) {
			this.getCurExpense(parseInt(item.id, 10));
			this.setCurrentExpenseMobile(item);
			const tsLink = this.tsDetailLink(item.id);
			this.$router.push({
				path: tsLink,
				query: {
					previousActiveTab: this.currentExpenseTab
				}
			});
			// this.$router.push({ path: tsLink });
		},
		// -----------------------------------------
		// ------------- MODALS --------------------
		// -----------------------------------------
		openExpenseModal(type, isNew) {
			this.$nextTick(() => {
				this.currEditExpense = null;
				this.isNewExpenseModalOpen = true;
				this.newExpenseType = type;
				this.isNew = isNew;
			});
		},
		checkFormValidity() {
			const valid = this.$refs.form.checkValidity();
			this.newExpense.nameState = valid;
			return valid;
		},
		/**
		 * @param {Object} expense
		 */
		async handleDeleteExpense(expense, showDialog = true) {
			if (isNil(expense)) return;

			const index = this.notSubmitedExpenses.indexOf(expense);
			const txt = this.FormMSG(98981, 'Please confirm that you want delete this expense');

			if (showDialog === true) {
				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.rendConfirmModalTxt.title,
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: this.rendConfirmModalTxt.okTitle,
						cancelTitle: this.rendConfirmModalTxt.cancelTitle,
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (!v) return;
						this.notSubmitedExpenses.splice(index, 1);
						await this.deleteExpense(expense.id).then(() => {});
					})
					.catch((err) => console.log(err));
			} else {
				this.notSubmitedExpenses.splice(index, 1);
				await this.deleteExpense(expense.id).then(() => {});
			}
		},
		async handSendAllExpensesValidation() {
			this.boxTwo = '';
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(98982, 'Please confirm that you want to send all unsent expenses to the validation'), {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((value) => {
					if (value) {
						this.isOpenTitleModalPC = true;
					}
				})
				.catch((err) => console.log(err));
		},
		onCancelTitleModalPC(payload) {
			this.isOpenTitleModalPC = payload;
		},
		renderNotificationSuccess() {
			var strTitle = this.FormMSG(162, 'Success');
			this.$bvToast.toast(this.FormMSG(161, 'Your expenses were sent for validation'), {
				title: strTitle,
				variant: 'success',
				solid: true,
				toaster: 'b-toaster-top-center',
				autoHideDelay: 3000
			});
			this.isOpenTitleModalPC = false;
			this.reinitializeTitle = true;
			this.maxItemSelected.ticket = 0;
			this.maxItemSelected.travel = 0;
			this.maxItemSelected.invoice = 0;
			this.msgWarnigMaxItemSelected = '';
			this.initData();
			this.itemsSelected = [];
			this.isSelectAll = false;
		},
		renderNotificationError() {
			var strTitle = this.FormMSG(52, 'Error');
			this.$bvToast.toast(this.FormMSG(53, 'Your expenses were not sent for validation'), {
				title: strTitle,
				variant: 'warning',
				solid: true,
				toaster: 'b-toaster-top-center',
				autoHideDelay: 3000
			});
			this.isOpenTitleModalPC = false;
			this.reinitializeTitle = true;
			this.maxItemSelected.ticket = 0;
			this.maxItemSelected.travel = 0;
			this.maxItemSelected.invoice = 0;
			this.msgWarnigMaxItemSelected = '';
			this.initData();
			this.itemsSelected = [];
			this.isSelectAll = false;
		},
		async onAddTitleModalPC(title) {
			let payload = title;
			let notSubmitedExpenses = this.notSubmitedExpenses.filter((item) => item.validated !== 16 && item.validated !== 4);
			if (this.itemsSelected.length === notSubmitedExpenses.length) {
				let result = await sendAllExpensesForValidation(payload);
				if (result) {
					this.renderNotificationSuccess();
				} else {
					this.renderNotificationError();
				}
			} else {
				let expenseData = [...this.itemsSelected].map((item) => {
					return { expenseItemId: +item.id, type: +item.type };
				});
				let result = await sendSelectedExpensesForValidation(payload, expenseData);
				if (result) {
					this.renderNotificationSuccess();
				} else {
					this.renderNotificationError();
				}
			}
		},
		getExpenseList(status) {
			this.handleTabChanged(status);
			switch (status) {
				case 0:
					this.notSubmittedState = true;
					this.submittedState = false;
					this.expenses = this.notSubmitedExpenses;

					break;
				case 1:
					this.notSubmittedState = false;
					this.submittedState = true;
					this.expenses = this.Expenses.submited;
					this.checkAll = false;
					this.switchCheckAll();

					break;
			}
		},
		rendAmountTotal(amountTotal) {
			return rendCurrency(amountTotal);
		},
		dateToLiteral(expenseDate) {
			const langCurrent = this.currentLang;
			const fullLiteralDate = moment(expenseDate).locale(`${langCurrent}`).format(`dddd, Do MMMM YYYY`);

			return fullLiteralDate;
		},
		rendCo2(kgCo) {
			return rendKgCo2(kgCo);
		},
		switchCheckAll() {
			// action before
			if (this.checkAll) {
				this.maxItemSelected.ticket = 0;
				this.maxItemSelected.travel = 0;
				this.maxItemSelected.invoice = 0;
				let numberTicket = 0;
				let numberTravel = 0;
				let numberInvoice = 0;

				for (let i = 0; i < this.expenses.length; i++) {
					const element = this.expenses[i];
					if (element.type === 0) {
						if (this.checkWithMaxExpenseItems === true) {
							if (numberTicket < store.state.myProject.maxExpenseItemsPerSheet) {
								if (element.amountTotal > 0) {
									numberTicket++;
									this.maxItemSelected.ticket++;
								}

								if (element.amountTotal > 0) {
									this.notSubmittedIds.push(parseInt(element.id, 10));
									document.getElementById(`${this.generateTextId('card-expense-' + element.id)}`).style.border = '1px solid #225CBD';
								}
							} else {
								if (numberTicket === store.state.myProject.maxExpenseItemsPerSheet - 1) {
									numberTicket++;
									this.maxItemSelected.ticket++;
								}
							}
						} else {
							if (element.amountTotal > 0) {
								this.notSubmittedIds.push(parseInt(element.id, 10));
								document.getElementById(`${this.generateTextId('card-expense-' + element.id)}`).style.border = '1px solid #225CBD';
							}
						}
					}
					if (element.type === 1) {
						if (this.checkWithMaxExpenseItems === true) {
							if (numberInvoice < store.state.myProject.maxExpenseItemsPerSheet) {
								if (element.amountTotal > 0) {
									numberInvoice++;
									this.maxItemSelected.invoice++;
								}

								if (element.amountTotal > 0) {
									this.notSubmittedIds.push(parseInt(element.id, 10));
									document.getElementById(`${this.generateTextId('card-expense-' + element.id)}`).style.border = '1px solid #225CBD';
								}
							} else {
								if (numberInvoice === store.state.myProject.maxExpenseItemsPerSheet - 1) {
									numberInvoice++;
									this.maxItemSelected.invoice++;
								}
							}
						} else {
							if (element.amountTotal > 0) {
								this.notSubmittedIds.push(parseInt(element.id, 10));
								document.getElementById(`${this.generateTextId('card-expense-' + element.id)}`).style.border = '1px solid #225CBD';
							}
						}
					}
					if (element.type === 2) {
						if (this.checkWithMaxExpenseItems === true) {
							if (numberTravel < store.state.myProject.maxExpenseItemsPerSheet) {
								if (element.amountTotal > 0) {
									numberTravel++;
									this.maxItemSelected.travel++;
								}

								if (element.amountTotal > 0) {
									this.notSubmittedIds.push(parseInt(element.id, 10));
									document.getElementById(`${this.generateTextId('card-expense-' + element.id)}`).style.border = '1px solid #225CBD';
								}
							} else {
								if (numberTravel === store.state.myProject.maxExpenseItemsPerSheet - 1) {
									numberTravel++;
									this.maxItemSelected.travel++;
								}
							}
						} else {
							if (element.amountTotal > 0) {
								this.notSubmittedIds.push(parseInt(element.id, 10));
								document.getElementById(`${this.generateTextId('card-expense-' + element.id)}`).style.border = '1px solid #225CBD';
							}
						}
					}
				}

				if (this.checkWithMaxExpenseItems === true) {
					this.checkNumberItemSelectedAll(numberTicket, numberTravel, numberInvoice);
				}
			} else {
				const ids = this.notSubmittedIds;
				for (let i = 0; i < ids.length; i++) {
					if (document.getElementById(`${this.generateTextId('card-expense-' + ids[i])}`)) {
						document.getElementById(`${this.generateTextId('card-expense-' + ids[i])}`).style.border = 'none';
					}
				}
				this.notSubmittedIds = [];
			}

			// end
			this.checkAll = !this.checkAll;
		},
		setSelectedItem(payload, item, id) {
			if (this.checkWithMaxExpenseItems === true) {
				if (item.type === 0) {
					if (this.maxItemSelected.ticket >= store.state.myProject.maxExpenseItemsPerSheet) {
						this.msgWarnigMaxItemSelected =
							this.FormMSG(481, 'Only ') +
							store.state.myProject.maxExpenseItemsPerSheet +
							' ' +
							this.FormMSG(413, 'tickets ') +
							this.FormMSG(482, 'have been selected to not exceed the maximum number of items per expense sheet defined in the project.');
						item.isSelected = false;
						this.showModalWarningItemNumber = true;
						this.notSubmittedIds = this.notSubmittedIds.filter((option) => +option !== +item.id);
					} else {
						if (payload.includes(+item.id)) {
							this.maxItemSelected.ticket++;
						} else {
							this.maxItemSelected.ticket--;
						}
					}
				}

				if (item.type === 1) {
					if (this.maxItemSelected.invoice >= store.state.myProject.maxExpenseItemsPerSheet) {
						this.msgWarnigMaxItemSelected =
							this.FormMSG(481, 'Only ') +
							store.state.myProject.maxExpenseItemsPerSheet +
							' ' +
							this.FormMSG(472, 'invoices ') +
							this.FormMSG(482, 'have been selected to not exceed the maximum number of items per expense sheet defined in the project.');
						item.isSelected = false;
						this.showModalWarningItemNumber = true;
						this.notSubmittedIds = this.notSubmittedIds.filter((option) => +option !== +item.id);
					} else {
						if (payload.includes(+item.id)) {
							this.maxItemSelected.invoice++;
						} else {
							this.maxItemSelected.invoice--;
						}
					}
				}

				if (item.type === 2) {
					if (this.maxItemSelected.travel >= store.state.myProject.maxExpenseItemsPerSheet) {
						this.msgWarnigMaxItemSelected =
							this.FormMSG(481, 'Only ') +
							store.state.myProject.maxExpenseItemsPerSheet +
							' ' +
							this.FormMSG(415, 'travels ') +
							this.FormMSG(482, 'have been selected to not exceed the maximum number of items per expense sheet defined in the project.');
						item.isSelected = false;
						this.showModalWarningItemNumber = true;
						this.notSubmittedIds = this.notSubmittedIds.filter((option) => +option !== +item.id);
					} else {
						if (payload.includes(+item.id)) {
							this.maxItemSelected.travel++;
						} else {
							this.maxItemSelected.travel--;
						}
					}
				}
			}
			// else {
			// 	this.notSubmittedIds = this.notSubmittedIds.filter((option) => +option !== +item.id);
			// }

			// is checked
			if (this.notSubmittedIds.findIndex((p) => p === +item.id) > -1) {
				document.getElementById(`${id}`).style.border = '1px solid #225CBD';
			} else {
				document.getElementById(`${id}`).style.border = 'none';
			}

			this.checkAll = !(payload.length > 0);
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		editExpenseMobile(item) {
			let type = item.type;
			let form = 0;
			if (item.type === 0 || item.type === 1) {
				form = 2;
			} else if (item.type === 2) {
				form = 1;
				item.originalKm = item.km;
				item.originalFromLocation = item.fromLocation;
				item.originalToLocation = item.toLocation;
			}
			this.setFocusExpenseItem(item);

			const path = `/myexpenses/${form}/u/${type}/${parseInt(item.id, 10)}`;
			this.$router.push({ path });
		},
		deleteExpenseMobile(id) {
			const txt = this.FormMSG(98981, 'Please confirm that you want delete this expense');

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;
					this.notSubmittedCopyIds.splice(
						this.notSubmittedCopyIds.findIndex((expenseId) => expenseId === id),
						1
					);
					this.expenses.splice(
						this.expenses.findIndex((expense) => expense.id === id),
						1
					);
					// console.log('deleteExpenseMobile: ', this.expenses.length);
					await this.deleteExpense(id).then(() => {
						this.createToastForMobile(this.FormMSG(43, 'Success'), this.FormMSG(342, 'Expense deleted successfully'), '');
					});
				})
				.catch((err) => console.log(err));
		},
		handleSendExpensesForValidationMobile() {
			this.itemsSelected = [];
			const itemsSelected =
				this.notSubmittedIds.length === this.notSubmittedCopyIds.length
					? this.notSubmitedExpenses.filter((expense) => expense.amountTotal !== 0 && expense.validated !== 4 && expense.validated !== 16)
					: this.notSubmitedExpenses.filter((expense) => this.notSubmittedIds.includes(expense.id));
			itemsSelected.forEach((expense) => {
				this.itemsSelected.push({
					expenseItemId: parseInt(expense.id, 10),
					type: parseInt(expense.type, 10)
				});
			});

			if (this.isFilmSingle) {
				this.isOpenTitleModal = true;
			} else {
				this.$bvModal
					.msgBoxConfirm(this.FormMSG(39, 'Please confirm that you want to send expense(s) for validation.'), {
						title: this.FormMSG(40, 'Confirm'),
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'primary',
						cancelVariant: 'light',
						okTitle: this.FormMSG(41, 'Send'),
						cancelTitle: this.FormMSG(42, 'Cancel'),
						footerClass: 'p-2',
						hideHeaderClose: true,
						centered: false,
						noCloseOnBackdrop: true
					})
					.then((value) => {
						if (value) {
							this.isOpenTitleModal = true;
						} else {
							this.checkAll = false;
							this.maxItemSelected.ticket = 0;
							this.maxItemSelected.travel = 0;
							this.maxItemSelected.invoice = 0;
							this.msgWarnigMaxItemSelected = '';

							this.switchCheckAll();
						}
					})
					.catch((err) => {
						// An error occurred
					});
			}
		},
		onCancelTitleModal(payload) {
			this.checkAll = false;
			this.switchCheckAll();
			this.isOpenTitleModalPC = payload;
			this.isOpenTitleModal = payload;
		},
		async onAddTitleModal(payload) {
			let result = await sendSelectedExpensesForValidation(payload, this.itemsSelected)
				.then(async (result) => {
					if (result) {
						this.createToastForMobile(this.FormMSG(162, 'Success'), this.FormMSG(47, 'Your expenses were sheet has been created.'));
						this.maxItemSelected.ticket = 0;
						this.maxItemSelected.travel = 0;
						this.maxItemSelected.invoice = 0;
						this.msgWarnigMaxItemSelected = '';

						await this.initData();
						this.notSubmittedIds = [];
						this.checkAll = true;
					} else {
						this.createToastForMobile(
							this.FormMSG(125, 'Error'),
							this.FormMSG(126, 'An error was occured during validate your expense(s), please contact your administrator.'),
							'',
							'danger'
						);
					}
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.isOpenTitleModal = false;
					this.reinitializeTitle = true;
				});
			return result;
		},
		showExpenseTitle(expense) {
			return this.submittedState && expense.description.replace(/[ \s]+/g, '') !== '' && expense.description.replace(/[ \s]+/g, '') !== `#${expense.id}`;
		},
		async onCloseExpenseForm(payload) {
			if (payload) {
				// await this.initData();
			}
		},
		recalculateDistanceByUnit(value) {
			return recalculateDistanceValueByUnit(value);
		},
		setIsSelectAll(payload) {
			if (payload === true) {
				this.itemsSelected = [];
				this.maxItemSelected.ticket = 0;
				this.maxItemSelected.travel = 0;
				this.maxItemSelected.invoice = 0;
				let numberTicket = 0;
				let numberTravel = 0;
				let numberInvoice = 0;

				this.notSubmitedExpenses = this.notSubmitedExpenses.map((item) => {
					if (item.validated === 4 || item.validated === 16) {
						return { ...item, isSelected: false };
					} else {
						if (item.type === 0) {
							if (this.checkWithMaxExpenseItems === true) {
								if (numberTicket < store.state.myProject.maxExpenseItemsPerSheet) {
									if (item.amountTotal > 0) {
										numberTicket++;
										this.maxItemSelected.ticket++;
									}

									return { ...item, isSelected: this.isSelectAll };
								} else {
									numberTicket++;
									this.maxItemSelected.ticket++;
									return { ...item, isSelected: false };
								}
							} else {
								return { ...item, isSelected: this.isSelectAll };
							}
						}

						if (item.type === 1) {
							if (this.checkWithMaxExpenseItems === true) {
								if (numberInvoice < store.state.myProject.maxExpenseItemsPerSheet) {
									if (item.amountTotal > 0) {
										numberInvoice++;
										this.maxItemSelected.invoice++;
									}

									return { ...item, isSelected: this.isSelectAll };
								} else {
									numberInvoice++;
									this.maxItemSelected.invoice++;
									return { ...item, isSelected: false };
								}
							} else {
								return { ...item, isSelected: this.isSelectAll };
							}
						}

						if (item.type === 2) {
							if (this.checkWithMaxExpenseItems === true) {
								if (numberTravel < store.state.myProject.maxExpenseItemsPerSheet) {
									if (item.amountTotal > 0) {
										numberTravel++;
										this.maxItemSelected.travel++;
									}

									return { ...item, isSelected: this.isSelectAll };
								} else {
									numberTravel++;
									this.maxItemSelected.travel++;
									return { ...item, isSelected: false };
								}
							} else {
								return { ...item, isSelected: this.isSelectAll };
							}
						}
					}
				});

				if (this.checkWithMaxExpenseItems === true) {
					this.checkNumberItemSelectedAll(numberTicket, numberTravel, numberInvoice);
				}

				this.itemsSelected = this.notSubmitedExpenses.filter(
					(item) => +item.amountTotal !== 0 && +item.validated !== 4 && item.validated !== 16 && item.isSelected === true
				);
			} else {
				this.notSubmitedExpenses = this.notSubmitedExpenses.map((item) => {
					return { ...item, isSelected: this.isSelectAll };
				});
				this.itemsSelected = [];
			}
		},
		checkNumberItemSelectedAll(numberTicket, numberTravel, numberInvoice) {
			let result = {
				ticket: true,
				travel: true
			};

			if (numberTicket > store.state.myProject.maxExpenseItemsPerSheet) {
				result.ticket = false;
				this.isSelectAll = false;
			}
			if (numberTravel > store.state.myProject.maxExpenseItemsPerSheet) {
				result.travel = false;
				this.isSelectAll = false;
			}

			if (numberTicket >= store.state.myProject.maxExpenseItemsPerSheet || numberTravel > store.state.myProject.maxExpenseItemsPerSheet) {
				this.msgWarnigMaxItemSelected = this.FormMSG(481, 'Only ');
				if (numberTicket >= store.state.myProject.maxExpenseItemsPerSheet) {
					this.msgWarnigMaxItemSelected += store.state.myProject.maxExpenseItemsPerSheet + ' ' + this.FormMSG(413, 'tickets ');
				}
				if (numberTicket >= store.state.myProject.maxExpenseItemsPerSheet) {
					this.msgWarnigMaxItemSelected += store.state.myProject.maxExpenseItemsPerSheet + ' ' + this.FormMSG(415, 'travels ');
				}
				if (numberInvoice >= store.state.myProject.maxExpenseItemsPerSheet) {
					this.msgWarnigMaxItemSelected += store.state.myProject.maxExpenseItemsPerSheet + ' ' + this.FormMSG(613, ' invoices ');
				}

				this.msgWarnigMaxItemSelected += this.FormMSG(
					482,
					'have been selected to not exceed the maximum number of items per expense sheet defined in the project.'
				);
				this.showModalWarningItemNumber = true;
			}

			return result;
		},
		handleChangeExpSelected($event, data) {
			if ($event) {
				if (this.checkWithMaxExpenseItems === true) {
					if (data.type === 0) {
						if (this.maxItemSelected.ticket >= store.state.myProject.maxExpenseItemsPerSheet) {
							this.msgWarnigMaxItemSelected =
								this.FormMSG(481, 'Only ') +
								store.state.myProject.maxExpenseItemsPerSheet +
								' ' +
								this.FormMSG(413, 'tickets ') +
								this.FormMSG(482, 'have been selected to not exceed the maximum number of items per expense sheet defined in the project.');
							data.isSelected = false;
							this.showModalWarningItemNumber = true;

							return;
						} else {
							if ($event === true) {
								this.maxItemSelected.ticket++;
							}
						}
					}

					if (data.type === 1) {
						if (this.maxItemSelected.invoice >= store.state.myProject.maxExpenseItemsPerSheet) {
							this.msgWarnigMaxItemSelected =
								this.FormMSG(481, 'Only ') +
								store.state.myProject.maxExpenseItemsPerSheet +
								' ' +
								this.FormMSG(413, 'tickets ') +
								this.FormMSG(482, 'have been selected to not exceed the maximum number of items per expense sheet defined in the project.');
							data.isSelected = false;
							this.showModalWarningItemNumber = true;

							return;
						} else {
							if ($event === true) {
								this.maxItemSelected.invoice++;
							}
						}
					}

					if (data.type === 2) {
						if (this.maxItemSelected.travel >= store.state.myProject.maxExpenseItemsPerSheet) {
							this.msgWarnigMaxItemSelected =
								this.FormMSG(481, 'Only ') +
								store.state.myProject.maxExpenseItemsPerSheet +
								' ' +
								this.FormMSG(415, 'travels ') +
								this.FormMSG(482, 'have been selected to not exceed the maximum number of items per expense sheet defined in the project.');
							data.isSelected = false;
							this.showModalWarningItemNumber = true;

							return;
						} else {
							if ($event === true) {
								this.maxItemSelected.travel++;
							}
						}
					}
				}

				this.itemsSelected = [...this.itemsSelected, data];
				let filterNotSubmittedExpenses = this.notSubmitedExpenses.filter(
					(item) => +item.amountTotal !== 0 && +item.validated !== 16 && +item.validated !== 4
				);
				this.itemsSelected.length === filterNotSubmittedExpenses.length ? (this.isSelectAll = true) : (this.isSelectAll = false);
			} else {
				if (this.checkWithMaxExpenseItems === true) {
					if (data.type === 0) {
						this.maxItemSelected.ticket--;
					}
					if (data.type === 1) {
						this.maxItemSelected.invoice--;
					}
					if (data.type === 2) {
						this.maxItemSelected.travel--;
					}
				}

				let filterNotSubmittedExpenses = this.notSubmitedExpenses.filter((item) => item.validated !== 16 && item.validated !== 4);
				this.itemsSelected = this.itemsSelected.filter((item) => item !== data);
				filterNotSubmittedExpenses.length !== this.itemsSelected.length ? (this.isSelectAll = false) : (this.isSelectAll = true);
			}
			return this.itemsSelected;
		},
		getError(payload) {
			if (!_.isNil(payload) && !_.isNil(payload.status) && payload.status === 509) {
				this.isNewExpenseModalOpen = true;
			}
		}
	}
};
</script>

<style>
.table-large-body {
	min-height: 480px;
}
.expense-mobile-icon-list {
	padding: 0 !important;
}
.expense-mobile-icon-list .label {
	font-size: 0.8rem;
	line-height: 1.1;
}
.my-expense-mobile-view-component-wrapper {
	margin-top: 1.5rem;
}
.btn-custom {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	position: sticky;
	width: 100%;
	bottom: 0;
	z-index: 999;
	padding: 10px;
}

#table-draft tbody tr {
	cursor: pointer;
}
</style>
